import React from "react"
import PropTypes from "prop-types"
import SEO from "./seo"

const Medication = ({ name, description, examples, sideEffects }) => {
	return (
		<div>
			<SEO
				title={`${name} - Lupus medications`}
				description={`Summary of ${name} and its role in managing and treating lupus. Covers how it works, things to watch out for, side effects and commonly available brands in Australia.`}
			/>
			<h1>{name}</h1>
			{description.map((par, i) => (
				<p key={i}>{par}</p>
			))}
			<h2>Side effects</h2>
			<p>
				Some potential side effects are listed below. If/when you start taking
				any medication, your pharmacist will provide you with a Consumer
				Medicine Information (CMI) sheet that will go over common side effects
				of each medication, plus the likelihood of having that side effect. If
				you have any concerns, speak to your doctor and/or pharmacist about
				them.
			</p>
			<ul>
				{sideEffects.map((e, i) => (
					<li key={i}>{e}</li>
				))}
			</ul>
			<h2>Example of medications in Australia</h2>
			<p>
				The list below contains the active ingredient plus some brand examples
				in Australia. It is not comprehensive - if you have any questions about
				them or related medications, contact your doctor or pharmacist.
			</p>
			<ul>
				{examples.map((e, i) => (
					<li key={i}>{e}</li>
				))}
			</ul>
		</div>
	)
}

Medication.propTypes = {
	name: PropTypes.string.isRequired,
	description: PropTypes.arrayOf(String).isRequired,
	examples: PropTypes.arrayOf(String).isRequired,
	sideEffects: PropTypes.arrayOf(String).isRequired,
}

export default Medication
