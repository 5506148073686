import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Tile from "../../components/tile"

export const medications = [
	{
		id: "nonsteroidal-anti-inflammatory-drugs-nsaids",
		name: "Nonsteroidal anti-inflammatory drugs (NSAIDs)",
		sideEffects: [
			"Heartburn",
			"Stomach ulcers",
			"Increased risk of cardiovascular events",
		],
		description: [
			"NSAIDs are one of the few lupus treatments that are available over the counter (OTC) in addition to requiring a prescription. These are comprised of a number of different medications - all of which are used to treat pain and inflammation.",
			"If you have asthma, stomach acid problems or kidney issues, then be careful with NSAIDs as they can all be exacerbated with their use.",
		],
		examples: [
			"Ibuprofen (Nurofen, Advil)",
			"Celecoxib (Celebrex)",
			"Diclofenac (Voltaren)",
			"Piroxicam (Feldene)",
			"Meloxicam (Mobic)",
			"Ketoprofen (Oruvail)",
			"Naproxen (Inza, Proxen SR)",
		],
	},
	{
		id: "hydroxychloroquine",
		name: "Antimalarials (hydroxychloroquine - Plaquenil)",
		description: [
			"Hydroxychloroquine is the predominant antimalarial used for lupus in Australia (largely because of the superior side effect profile compared to chloroquine). It slowly decreases the inflammation and prevents damage caused by lupus to your organs.",
			"Continued use has also been shown to reduce flare-ups. Plaquenil is one of the more common long-term medications used for managing lupus.",
			"Antimalarials for lupus can take several months to become effective, so they’re only given if disease is relatively mild and does not require stronger drugs. Side effects are usually mild. However, long term use of these drugs can cause vision changes in some people. If you take any of these drugs, you need to have your vision checked as often as your doctor suggests.",
		],
		sideEffects: [
			"Nausea/Vomiting/Diarrhea and other Gastrointestinal (GI) effects these usually settle in a few weeks",
			"Allergic skin reactions",
			"Ocular effects (effects of the eye such as blurred vision, retinopathy etc. seen with long term use >3-5 years)",
		],
		examples: ["Hydroxychloroquine (Plaquenil)"],
	},
	{
		id: "corticosteroids",
		name: "Corticosteroids (or glucocorticoids or steroids)",
		description: [
			`These medications mimic the hormone cortisol, which is naturally produced by the body. Cortisol is important in the regulation of the body’s immune response and this helps to ease lupus symptoms.`,
			`Steroids tend to work quickly but have long term side effects. For this reason, they are usually used initially and during flare ups rather than as long term therapy.`,
		],
		sideEffects: [
			"Weight gain",
			"Sleeping disturbances",
			"Fluid retention",
			"Reduced resistance to infection",
		],
		examples: [
			"Prednisolone (Solone, Panafcortelone)",
			"Prednisone (Sone, Predsone)",
		],
	},
	{
		id: "methotrexate",
		name: "Methotrexate",
		description: [
			"WARNING: Methotrexate can have severe side effects, particularly if it builds up in the system. Be aware of its usual once a week dosing regimen as well as what its signs of toxicity are.",
			"Methotrexate suppresses inflammation and immune responses however it isn't exactly known how it works. Folic acid supplements help to reduce the severity of side effects (though these must not be taken on the same day of week as the methotrexate dose.",
		],
		sideEffects: [
			"GI toxicity - nausea, vomiting, diarrhea, mouth ulcers",
			"Alopecia (hair loss - generally mild and reversible)",
			"Increased sensitivity to light",
		],
		examples: [
			"Methotrexate (Pfizer is the predominant supplier of this medication in Australia and most pharmacies will have just the one brand)",
		],
	},
	{
		id: "azathioprine",
		name: "Azathioprine",
		description: [
			"Azathioprine is metabolised by the body into a molecule that disrupts the creation of lymphocytes. Lymphocytes are a type of white blood cell that have a role in the body's immune response. Reducing their production, in turn, reduces the body's autoimmune response which helps with lupus symptoms.",
			"By extension, like all DMARDs, those on azathioprine need to be extra cautious regarding infections as they are at an increased risk of getting them.",
		],
		sideEffects: [
			"Infection",
			"Diarrhea",
			"Anorexia",
			"Nausea and vomiting",
			"Mouth ulceration ",
		],
		examples: ["azathioprine (Azapin, Imazan, Imuran, Thioprine)"],
	},
	{
		id: "cyclophosphamide",
		name: "Cyclophosphamide",
		description: [
			"Cyclophosphamide is thought to cause DNA strands within lymphocytes to cross-link. This effect on lymphocytes stops their immune response and helps to ease lupus symptoms.",
			"You will need to monitor complete blood counts while on this medication.",
		],
		sideEffects: ["Nausea", "Vomiting", "Infertility"],
		examples: ["Cyclophosphamide (Cyclonex, Endoxan)"],
	},
	{
		id: "other-pain-medications",
		name: "Other pain medications",
		description: [
			"While NSAIDs are the primary painkillers used in lupus, if they are not being tolerated or if their pain killing effects are not sufficient, your doctor may recommend additional pain killing medications.",
			"Depending on which medication is used, each will have its own side effects and things to watch out for.",
		],
		sideEffects: ["Varies depending on which painkiller is prescribed."],
		examples: ["Paracetamol (Panadol, Panamax), Tramadol (Tramal, Tramedo)"],
	},
	{
		id: "leflunomide",
		name: "Leflunomide",
		description: [
			`Leflunomide is a DMARD that inhibits the proliferation of lymphocytes, which in turn suppresses the body's auto-immune response. This, in turn, will reduce pain and inflammation associated with lupus.`,
			`The most common side effects with leflunomide tend to be bowel related and are generally manageable, getting better after the first few weeks of treatment. Drinking plenty of water may also help with this.`,
			`As with any medication, if the side effects are too severe, please speak to your doctor and/or pharmacist if you notice anything that concerns you - as with other immunosuppressive medications, care must be taken with regards to allergies, infections and less common but more severe side effects.`,
			`Leflunomide is a medication that requires you to not fall pregnant while you are on it. Please use effective contraception during treatment and for a few months after stopping treatment, because the active metabolite of leflunomide has a long half-life.`,
			`You will need to undergo regular blood tests while using leflunomide (every 2 to 4 weeks to start with and then dropping down in frequency as clinically required, usually to every 3 months).`,
		],
		sideEffects: [
			"Abdominal pain",
			"Diarrhea",
			"Nausea",
			"Vomiting",
			"Liver damage (rare, but it is recommended that you do not drink alcohol during treatment)",
		],
		examples: ["Leflunomide (Arava, Arabloc)"],
	},
	{
		id: "mycophenolate",
		name: "Mycophenolate (mofetil or sodium)",
		description: [
			"Mycophenolate comes in two salts (different forms of the same compound), both of which are converted in the body to the same active compound. This compound selectively suppresses the proliferation of lymphocytes and antibodies, thus reducing the body's autoimmune response.",
			"You will require regular blood monitoring while using this medication - each week for the first month and then gradually dropping down in frequency, usually down to every 1 - 3 months. Depending on your blood results, your doctor will vary the dose or pause treatment if required, so it is important that you do not miss these.",
		],
		sideEffects: [
			"Diarrhea",
			"Nausea",
			"Vomiting",
			"Abdominal pain",
			"Abnormal liver function tests",
			"Infections",
			"Fatigue",
			"Headache",
			"Cough",
		],
		examples: [
			"Mycophenolate mofetil (CellCept, Ceptolate, MycoCept)",
			"Mycophenolate sodium (Myfortic)",
		],
	},
	{
		id: "rituximab",
		name: "Rituximab",
		description: [
			`Rituximab is an IV administered medication. It is a monoclonal antibody, which suppresses the immune response and inflammation by reducing the production of cytokines. Cytokines are signaling molecules important in the inflammatory response, so reducing the production of cytokines has a direct effect on modifying inflammation.`,
			`The rate at which the IV infusion is done will generally start slow and increase over time if well tolerated. This is important in managing infusion related reactions (especially when starting treatment).`,
			`A complete blood count should be monitored while undergoing treatment with rituximab. Monitoring for signs and symptoms of leucoencephalopathy (a very rare - less than 0.1% - side effect of rituximab affecting brain tissue) should also be done for up to 12 months after your last dose.`,
		],
		sideEffects: [
			"Infusion-related reactions - fever, chills, nausea, vomiting, itch",
			"Injection site reactions (eg erythema, pain, swelling)",
			"Infections",
			"Decreased immunoglobulin levels",
			"Arrhythmias",
			"Musculoskeletal pain",
		],
		examples: ["Rituximab, (Mabthera, Riximyo"],
	},
	{
		id: "belimumab",
		name: "Belimumab",
		description: [
			"Belimumab is an IV infused medication that falls under the group of medications called 'cytokine modulators'. Cytokines are signalling molecules important in the inflammatory response, so reducing the production of cytokines has a direct effect on modifying inflammation. If there is no improvement in symptoms after six months, then treatment will generally be stopped.",
			"As with other IV infused medications, hypersensitivity is a concern - if you notice any difficulty breathing, fever, chills, rash or feel faint, you must let the doctor or nurse know. Keep in mind that these symptoms may happen a few hours after an infusion - your doctor or nurse will give you information on what to do if you notice anything.",
			"Live vaccines are generally avoided while undergoing treatment so it is important to be up to date with your vaccinations prior to starting treatment.",
		],
		sideEffects: [
			"Infections",
			"Hypersensitivity and infusion-related effects",
			"Diarrhea",
			"Nausea",
			"Leucopenia",
			"Depression",
			"Anxiety",
		],
		examples: ["Belimumab (Benlysta"],
	},
]

const Medications = () => (
	<Layout>
		<SEO title="Treatment options for lupus" />
		<h1>Treatment options</h1>
		<p>
			While there is no known root cause or cure, the progress of recent
			discoveries into the mechanisms of the lupus disease process and new
			treatments currently in development is encouraging.
		</p>
		<h2>Medications</h2>
		<p>
			While there is no silver bullet and no single medication is helpful in all
			cases of lupus, there are several classes of medications that have shown
			to assist with managing the symptoms associated with lupus, either
			directly or by reducing the body’s autoimmune response.
		</p>
		<p>
			As with all medications, each of these have side effects. You need to have
			an open discussion with your doctor and find the right medication(s) that
			provide the most symptomatic relief alongside the best side effect
			profile. It is worth noting that not everyone gets the side effects and
			hopefully you won’t either!
		</p>
		<p>
			The right medication or combination of medications will be different for
			everyone and may involve some trial and error. We recommend that you
			record your response to each medication (the tools listed in the next
			section are helpful with this) and continue having open and honest
			discussions with your doctor until you find what is best for you.
		</p>
		<p>
			The combination of medications for your lupus can also change over time.
		</p>
		<h3>A note on DMARDs (Disease-modifying antirheumatic drugs )</h3>
		<p>
			&apos;DMARDs&apos; is a term you are likely to come across in any
			conversation of medications used to treat lupus. This group of medications
			encompasses several different drugs and while they do not all share the
			same mechanism of action, they all help to suppress an overactive immune
			system.
		</p>
		<p>
			Treatment with any DMARD that suppresses the immune system increases the
			risk and severity of infection. Care must be taken and the frequency and
			severity of patients on DMARDs will play a role in using these drugs in
			controlling lupus.
		</p>
		<p>
			Due to the varying nature of the individual DMARDs, they have been listed
			here individually rather than being grouped together.
		</p>
		<h3>Medications used to treat lupus</h3>
		<div className="grid grid-cols-1 gap-4">
			{medications.map(({ name, id }) => (
				<Tile
					key={id}
					text={name}
					path={`/treatment-options/medications/${id}`}
				/>
			))}
		</div>

		<h2>Comorbidities</h2>
		<p>
			In addition to the direct symptoms of lupus, it may be necessary to treat
			and manage possible comorbidities that come with having lupus. These are
			other medical conditions that lupus patients may be at a greater risk of
			developing. For example, lupus patients are sometimes put on
			anticoagulants to mitigate their higher risk of getting blood clots.
		</p>
		<p>
			This is something that will be taken into consideration by your doctor.
		</p>
	</Layout>
)

export default Medications
