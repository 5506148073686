import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { MdOpenInNew } from "react-icons/md"

const Tile = ({ text, path, showIcon }) => {
	if (!showIcon) {
		return (
			<Link
				to={path}
				className="shadow-md block p-3 md:p-6 bg-purple-200 w-full text-purple-900 hover:bg-purple-300 text-xl focus:outline-none focus:shadow-outline"
			>
				{text}
			</Link>
		)
	}
	return (
		<Link
			to={path}
			className="flex items-center shadow-md block p-3 md:p-6 bg-purple-200 w-full text-purple-900 hover:bg-purple-300 text-xl focus:outline-none focus:shadow-outline"
		>
			<MdOpenInNew className="mr-6 flex-grow-0 flex-shrink-0 my-auto" />
			<span className="flex-grow">{text}</span>
		</Link>
	)
}

Tile.propTypes = {
	text: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	showIcon: PropTypes.bool,
}

Tile.defaultProps = {
	showIcon: true,
}

export default Tile
